<template>
  <v-dialog v-model="show" width="85%" :persistent="true">
    <v-card class="card-bg" v-if="!dialogError && show">
      <AppOverlay :absolute="false" :isLoading="componentLoading" />
      <!-- <v-card-title class="text-h5 pa-9"> ค้นหาผู้ขับ </v-card-title> -->

      <v-app-bar elevation="0" class="card-bg" color="transparent">
        <v-toolbar-title class="text-h6"> เพิ่มผู้ขับ </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="cancelStatus()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col lg="4" cols="12" class="px-0">
            <v-row dense class="px-3">
              <v-col cols="12" lg="6" md="6" sm="12">
                <CustomDateTimePickerFormInput
                  placeholder="เลือกเวลาเริ่มงาน"
                  title="เวลาเริ่มงาน"
                  v-model="formData.startDate"
                  @input="processStartDateFromPicker"
                  :isRequired="false"
                  :hideDetail="true"
                  :clearable="false"
                  :expendMargin="false"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12">
                <CustomDateTimePickerFormInput
                  placeholder="เลือกเวลาจบงาน"
                  title="เวลาจบงาน"
                  v-model="formData.endDate"
                  @input="processEndDateFromPicker"
                  :isRequired="false"
                  :minDate="today"
                  :hideDetail="true"
                  :clearable="false"
                  :expendMargin="false"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12">
                <InputView
                  v-if="!isStartEndDateFiled"
                  title="ค้นหาผู้ขับ"
                  :isRequired="false"
                >
                  <v-text-field
                    class="white"
                    outlined
                    dense
                    placeholder="พิมพ์ชื่อและกด Enter"
                    v-model="searchDriverValue"
                    @keyup="EnterToSearchDriverHandler"
                    :disabled="isStartEndDateFiled"
                    prepend-inner-icon="mdi-magnify"
                    hide-details=""
                    @click:clear="clearInput()"
                  ></v-text-field>
                </InputView>
                <div v-else>
                  <p class="mt-2 text-center font-weight-bold">
                    โปรดเลือกและบันทึกเส้นทางก่อนเลือกผู้ขับ
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row class="pr-0 mt-3">
              <v-col cols="12" class="px-0 py-0 my-0">
                <v-list
                  v-if="drivers.length !== 0"
                  dense
                  style="overflow-y: auto"
                  class="card-bg white_list"
                  transition="scale-transition"
                >
                  <v-list-item-group v-model="selectedValueIdxList">
                    <div v-for="(driver, i) in drivers" :key="`dr${i}`">
                      <v-list-item two-line class="px-3">
                        <v-list-item-content>
                          <v-col cols="12" class="py-1 d-flex">
                            <v-avatar
                              class="align-self-center"
                              height="40"
                              width="40"
                            >
                              <img :src="driver.profileImage" />
                            </v-avatar>
                            <div class="pl-2 align-self-center">
                              <p
                                class="ma-0 text-app-detail font-weight-bold black--text"
                              >
                                {{ driver.dpNameTh }}
                                <v-chip
                                  small
                                  color="success"
                                  v-if="driver.available === 1"
                                >
                                  ว่าง
                                </v-chip>
                                <v-chip
                                  small
                                  color="error"
                                  v-if="driver.available === 0"
                                >
                                  ไม่ว่าง
                                </v-chip>
                                <v-chip
                                  small
                                  color="error"
                                  v-if="driver.available === -1"
                                >
                                  ไม่ว่าง (ลา)
                                </v-chip>
                              </p>
                              <p class="ma-0 py-1 text-app-normal">
                                เบอร์โทร: {{ driver.dpPhoneNumeber }}
                              </p>
                            </div>
                            <v-spacer></v-spacer>
                            <div class="align-self-center" align="right">
                              <v-chip
                                v-if="checkDriverHasScore(driver)"
                                color="warning"
                                dark
                                ><v-icon class="pr-1">mdi-star</v-icon>
                                <span>
                                  {{ driver.score.summary }}
                                </span>
                              </v-chip>
                            </div>
                          </v-col>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="i < drivers.length - 1"
                        :key="`dv${i}`"
                      ></v-divider>
                    </div>
                  </v-list-item-group>
                </v-list>

                <v-card
                  v-else
                  elevation="0"
                  class="d-flex justify-center align-center card-bg"
                >
                  <p
                    v-if="componentLoading"
                    class="text-app-detail-normal align-stretch"
                  >
                    กำลังดึงข้อมูล...
                  </p>
                  <p
                    v-else-if="isStartEndDateFiled"
                    class="text-app-detail-normal align-stretch red--text"
                  >
                    โปรดระบุเวลาเริ่มงานและเวลาจบงานในแท็บข้อมูลงาน
                  </p>
                  <p v-else class="text-app-detail-normal align-stretch">
                    ไม่มีข้อมูล
                  </p>
                </v-card>
              </v-col>
            </v-row></v-col
          >

          <v-col lg="8" cols="12">
            <v-card class="mb-2" outlined v-if="selectedDriver !== null">
              <v-card-text class="d-flex">
                <div class="d-flex">
                  <v-avatar height="50" width="50">
                    <img
                      v-if="selectedDriver"
                      :src="
                        selectedDriver.profileImage
                          ? selectedDriver.profileImage
                          : null
                      "
                    />
                  </v-avatar>
                  <div class="pl-2 align-self-center">
                    <div
                      v-if="selectedDriver"
                      class="ma-0 py-1 text-app-detail"
                    >
                      <p class="mb-0 font-weight-bold black--text">
                        ผู้ขับที่ถูกเลือก: {{ selectedDriver.dpNameTh }}
                      </p>

                      <p class="mb-0">
                        เบอร์โทร: {{ selectedDriver.dpPhoneNumeber }}
                        <v-chip
                          v-if="selectedDriver.score"
                          color="warning"
                          dark
                          small
                          ><v-icon class="pr-1">mdi-star</v-icon
                          ><span class="text-app-detail">{{
                            selectedDriver.score
                              ? selectedDriver.score.summary
                              : "-"
                          }}</span></v-chip
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <v-spacer></v-spacer>
                <div
                  class="mx-0 px-0 pb-0 mb-0 align-self-center"
                  align="right"
                >
                  <PrimaryButton
                    :disabled="!isNewDriverSelected"
                    v-if="isNewDriverSelected"
                    @on-click="saveStatus()"
                  >
                    มอบหมายงาน
                  </PrimaryButton>
                  <!-- <v-btn
                    v-else
                    :color="$vuetify.theme.themes.light.borderColor"
                    class="black--text font-weight-bold px-11"
                    elevation="0"
                    light
                    large
                    @click="cancelStatus()"
                  >
                    ยกเลิก
                  </v-btn> -->
                </div>
              </v-card-text>
            </v-card>

            <v-card class="pa-0" outlined elevation="0">
              <v-sheet height="100%">
                <ScheduleCalendarComponent
                  ref="AddDriverDialogCalendar"
                  :key="componetKeyCounter"
                  :selected-calendar-of-driver="selectedCalendar"
                  v-if="show"
                  :selected-start-date="formData.startDate"
                  :selected-end-date="formData.endDate"
                  @is-overlap-selected-event="checkOverLapSelectedEvent"
                  vuex-store-name="jobs"
                />
                <!-- <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                  >
                    <v-card color="grey lighten-4" min-width="350px" flat>
                      <v-toolbar :color="selectedEvent.color" dark>
                        <v-btn icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-toolbar-title
                          v-html="selectedEvent.name"
                        ></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon>
                          <v-icon>mdi-heart</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text>
                        <span v-html="selectedEvent.details"></span>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error" @click="selectedOpen = false">
                          ยกเลิก
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="selectedOpen = false"
                        >
                          บันทึก
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu> -->
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelStatus()"> ยกเลิก </v-btn>
          <v-btn color="primary" text @click="saveStatus()"> บันทึก </v-btn>
        </v-card-actions> -->
    </v-card>
    <v-card v-else>
      <v-card-title> เกิดข้อผิดพลาด </v-card-title>
      <v-card-text>
        <v-btn @click="cancelStatus()" text> ปิด </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import CustomTextInput from "@/components/CustomTextInput.vue";
import CustomTextareaImprove from "@/components/CustomTextareaImprove.vue";
import CustomCardFormViewModern from "@/components/CustomCardFormViewModern.vue";
import { dateStringToDateFormat } from "@/services/appDate";
import CustomDateTimePickerFormInput from "@/components/CustomDateTimePickerFormInput";
import CustomSelectsRoleOfIcon from "@/components/CustomSelectsRoleOfIcon.vue";
import InputView from "@/components/InputView.vue";
import SaveJobsFormButton from "../SaveJobsFormButton.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import CustomTextInputImprove from "@/components/CustomTextInputImprove.vue";
import NormalButton from "@/components/NormalButton.vue";
import magnify_image from "@/assets/search.png";
import { mapGetters } from "vuex";
import AppOverlay from "@/components/AppOverlay.vue";
import {
  getAvailableDriverService,
  getJobCalendarOfDriverService,
} from "@/services/api/jobs";
import moment from "moment";
import ScheduleCalendarComponent from "./ScheduleCalendarComponent.vue";

export default {
  name: "AddDriverDialogComponent",
  props: {
    vuexStoreName: {
      type: String,
      default: "manageRoute",
    },
    alwaysReturnData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchIcon: magnify_image,
    searchDriverValue: "",
    drivers: [],
    selectedDriver: null,
    selectedCalendar: null,
    selectedValueIdxList: null,
    //
    searchboxLoading: false,
    isNewDriverSelected: false,
    today: null,

    isNewEventAdded: false,
    show: false,
    resolve: null,
    reject: null,
    dialogError: false,
    //
    fundData: {
      roletype: [
        { key: "OWNER", value: "Owner" },
        { key: "DRIVER", value: "ผู้ขับ" },
      ],
    },
    formData: {
      roletype: null,
      startDate: null,
      endDate: null,
    },
    // for api
    queryStringObject: null,
    componentLoading: false,
    componetKeyCounter: 1,
  }),
  watch: {
    selectedValueIdxList: {
      immediate: false,
      handler(valueIdx) {
        if (!_.isNil(valueIdx)) {
          this.selectedDriverHandler(this.drivers[valueIdx]);
        } else {
          this.selectedDriver = null;
          this.selectedCalendar = null;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      jobsStatusColor: "jobs/jobsStatusColor",
      jobsStatusColorValue: "jobs/jobsStatusColorValue",
      jobsStatusText: "jobs/jobsStatusText",

      driverAndVehicleStatusColor: "jobs/driverAndVehicleStatusColor",
      driverAndVehicleStatusColorValue: "jobs/driverAndVehicleStatusColorValue",
      driverAndVehicleStatusText: "jobs/driverAndVehicleStatusText",
    }),
    isStartEndDateFiled() {
      if (
        !_.isNil(this.formData["startDate"]) &&
        !_.isNil(this.formData["endDate"])
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    dateStringToDateFormat: dateStringToDateFormat,
    checkDriverHasScore(hasScore) {
      if (_.isNil(hasScore)) {
        return false;
      }

      let getSummaryScore = _.get(hasScore, "score.summary", null);
      if (getSummaryScore !== null) {
        return true;
      }

      return false;
    },
    resetFormData() {
      this.formData = {
        roletype: null,
        startDate: null,
        endDate: null,
      };
      this.clearInput();
    },
    // async requestDriver() {
    //   await this.$refs.driverCalendar.open();
    // },
    getDate(val) {
      if (!_.isNil(val)) {
        return val.split(" ")[0];
      }
      return null;
    },
    getTime(val) {
      if (!_.isNil(val)) {
        return val.split(" ")[1];
      }
      return null;
    },
    selectedDriverHandler(driver) {
      // this.isNewDriverSelected = true;
      this.selectedDriver = driver;
      this.getCalendarApi(driver);
    },

    // for search driver function
    async EnterToSearchDriverHandler(event) {
      if (event.code == "Enter") {
        this.selectedValueIdxList = null;
        this.componetKeyCounter += 1;
        this.getDataFromApi({
          assignActive: false,
          getCalendarApi: false,
        });
      }
    },
    // for datetime picker
    processStartDateFromPicker(value) {
      this.formData["startDate"] = value;
      if (!_.isNil(this.formData["endDate"])) {
        let start = new Date(this.formData["startDate"]);
        let end = new Date(this.formData["endDate"]);

        if (start > end) {
          this.formData["endDate"] = this.formData["startDate"];
        }

        this.getDataFromApi({
          assignActive: false,
          getCalendarApi: true,
        });
      }
      this.today = this.getDate(this.formData["startDate"]);
    },

    processEndDateFromPicker(value) {
      this.formData["endDate"] = value;
      if (!_.isNil(this.formData["endDate"])) {
        this.getDataFromApi({
          assignActive: false,
          getCalendarApi: true,
        });
      }
    },

    setDateTimeToQueryParams() {
      if (
        !_.isNil(this.formData["startDate"]) &&
        !_.isNil(this.formData["endDate"])
      ) {
        let _startDate = moment(this.formData["startDate"]); // +07.00
        let _endDate = moment(this.formData["endDate"]); // +07.00

        return {
          startdate: _startDate.format("YYYY-MM-DDTHH:mm:ss"),
          enddate: _endDate.format("YYYY-MM-DDTHH:mm:ss"),
        };
      }
      if (!_.isNil(this.formData["startDate"])) {
        let _startDate = moment(this.formData["startDate"]); // +07.00
        let _endDate = moment(this.formData["startDate"]).add(36, "hours"); // +07.00

        return {
          startdate: _startDate.format("YYYY-MM-DDTHH:mm:ss"),
          enddate: _endDate.format("YYYY-MM-DDTHH:mm:ss"),
        };
      } else {
        return { startdate: null, enddate: null };
      }
    },

    async getCalendarApi(driver) {
      let _queryStringObj = this.setDateTimeToQueryParams();

      if (
        !_.isNil(_queryStringObj["startdate"]) &&
        !_.isNil(_queryStringObj["enddate"]) &&
        !_.isNil(driver)
      ) {
        _queryStringObj["id"] = driver["_id"];
        let queryString = new URLSearchParams(_queryStringObj).toString();

        let responseData = await getJobCalendarOfDriverService(
          this.$store,
          queryString
        );
        if (responseData["code"] === 200) {
          this.selectedCalendar = responseData["data"];
        } else {
          console.error("getCalendarAPI: ERROR 1");
          this.dialogError = true;
        }
      }
    },
    //
    async assignActive() {
      // === query to calendar selected driver
      const whoIsActive = _.get(
        this.$store.state[this.vuexStoreName],
        "jobData.driverId",
        null
      );

      if (!_.isNil(whoIsActive)) {
        this.selectedDriver = _.cloneDeep(whoIsActive);
      }
    },
    //
    async getDataFromApi(settingParams) {
      this.componentLoading = true;
      // startdate, enddate and id
      if (settingParams["assignActive"]) {
        this.assignActive();
      }
      if (settingParams["getCalendarApi"]) {
        await this.getCalendarApi(this.selectedDriver);
      }

      this.queryStringObject = {};
      this.drivers = [];
      this.queryStringObject = {
        ...this.queryStringObject,
        ...this.setDateTimeToQueryParams(),
      };

      // check text to query user
      if (this.searchDriverValue.length !== 0) {
        this.queryStringObject["text"] = this.searchDriverValue;
      }
      //

      this.queryStringObject["id"] = _.get(
        this.$store.state[this.vuexStoreName],
        "jobData._id",
        null
      );

      // DONE:: for test
      // this.queryStringObject["limit"] = 50;
      if (
        !_.isNil(this.queryStringObject["startdate"]) &&
        !_.isNil(this.queryStringObject["enddate"])
      ) {
        let queryString = new URLSearchParams(
          this.queryStringObject
        ).toString();

        let responseData = await getAvailableDriverService(
          this.$store,
          queryString
        );

        if (responseData["code"] === 200) {
          this.drivers = responseData["data"];
          // check active now driver
        } else {
          // TODO:: loading fail
          console.error("getDataFromApi: ERROR 1");
          this.dialogError = true;
        }
      } else {
        // load fail
        console.error("getDataFromApi: ERROR_2_FORM_DATA_FAIL");
        // this.dialogError = true;
      }

      this.componentLoading = false;
    },
    //
    checkOverLapSelectedEvent(isOverLap) {
      // console.log("checkOverLapSelectedEvent", isOverLap);
      // form parent
      if (this.alwaysReturnData) {
        this.isNewDriverSelected = true;
      } else {
        this.isNewDriverSelected = !isOverLap;
      }
    },

    // dialog function
    clearInput() {
      this.searchDriverValue = "";
      this.drivers = [];
      this.isNewDriverSelected = false;
      this.selectedDriver = null;
      this.dialogError = false;
    },

    open() {
      this.clearInput();
      this.show = true;
      if (this.vuexStoreName === "manageRoute") {
        this.formData["startDate"] =
          this.$store.state[this.vuexStoreName].startDate || null;
        this.formData["endDate"] =
          this.$store.state[this.vuexStoreName].endDate ||
          this.$store.state[this.vuexStoreName].jobData.estimateEndDate ||
          null;
      } else {
        if (!_.isNil(this.$store.state[this.vuexStoreName]["jobData"])) {
          this.formData["startDate"] =
            this.$store.state[this.vuexStoreName].jobData.startDate || null;
          this.formData["endDate"] =
            this.$store.state[this.vuexStoreName].jobData.endDate ||
            this.$store.state[this.vuexStoreName].jobData.estimateEndDate ||
            null;
        }
      }

      this.today = this.getDate(this.formData["startDate"]);
      this.getDataFromApi({
        assignActive: true,
        getCalendarApi: true,
      });

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    saveStatus() {
      this.resolve(this.selectedDriver);
      this.resetFormData();
      this.show = false;
    },
    cancelStatus() {
      this.resetFormData();
      this.resolve(null);
      this.show = false;
    },
  },
  components: {
    CustomTextInput,
    CustomTextareaImprove,
    CustomCardFormViewModern,
    CustomDateTimePickerFormInput,
    ScheduleCalendarComponent,
    InputView,
    CustomSelectsRoleOfIcon,
    SaveJobsFormButton,
    PrimaryButton,
    CustomTextInputImprove,
    NormalButton,
    AppOverlay,
  },
  mounted() {},
};
</script>

<style scoped>
/* .title-filter {
  font-size: 14px;
  padding-bottom: 6px;
  color: rgb(137, 137, 137);
} */
.landing-not-selected {
  margin: auto;
  border: dashed;
  height: 600px;
}
.card-bg {
  background-color: #eff1f7;
}
.icon_magnify {
  font-size: 100px;
}
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
.white_list .v-list-item-group .v-list-item--active {
  background-color: white;
  color: white;
}
.v-list {
  height: 400px; /* or any height you want */
  overflow-y: auto;
}
.image {
  opacity: 0.7;
}
</style>
